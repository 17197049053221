body {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.tablink {
  background-color: #999999;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 50%;
  margin-top: 20px;
}

.tablink:hover {
  background-color: #38a8ff;
}

.tabcontent {
  color: black;
  display: none;
  padding: 100px 20px;
}

.tablink1 {
  background-color: #38a8ff;
  color: white;
  float: left;
  border: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.116);
  border-bottom: 4px solid #38a8ff;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 50%;
  border-radius: 0px;
}

.tabcontent1 {
  color: black;
  display: none;
  padding: 80px 0px 10px 0px;
}

.tablink2 {
  background-color: white;
  color: #38a8ff;
  float: left;
  border: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.116);
  border-bottom: 4px solid #38a8ff;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 14px;
  width: 50%;
  border-radius: 0px;
  margin: 20px 0px 20px 0px;
}

.tabcontent2 {
  color: black;
  display: none;
  padding: 100px 0px 10px 0px;
}

.column {
  float: left;
  width: 50%;
  padding: 0 10px;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 10px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 5px;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.container {
  padding: 20px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

h4 {
  text-align: left;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
  color: black;
}

h5 {
  text-align: left;
  margin: 5px 0px 15px 0px;
  color: black;
  font-size: 15px;
  opacity: 80%;
}

h6 {
  text-align: left;
  margin: 5px 0px 10px 0px;
}

.card-btn {
  width: 100%;
}

.card-detail {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 15px;
  font-size: 14px;
  text-align: left;
  margin-bottom: 20px;
  color: rgb(71, 71, 71);
}

.btnNearMe {
  background: rgba(56, 169, 255, 0.199);
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 100%;
  right: 33px;
  position: absolute;
  text-justify: center;
  text-align: center;
}

.btnUploadConsent {
  background: rgb(248, 239, 241);
  color: rgb(236, 134, 151);
  font-weight: bold;
  height: 50px;
  width: 100%;
  border-color: rgb(236, 134, 151);
  border-style: dotted;
  border-radius: 10px 10px 10px 10px;
  margin: 10px 0px 10px 0px;
  font-size: 14px;
}

.btnNextAction {
  background: #38a8ff;
  color: white;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 10px 10px 10px 10px;
  margin: 10px 0px 30px 0px;
}

.column-prosedur {
  float: left;
  width: 33.33%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row-prosedur:after {
  content: "";
  display: table;
  clear: both;
}

table td {
  border: none !important;
  font-size: 14px;
}

.btnPlusMin {
  width: 25px;
  height: 25px;
  background: white;
  border: 1px solid lightgrey;
  font-size: 14px;
  text-align: center;
}

.btnTambah {
  background: #38a8ff;
  color: white;
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 10px 10px 10px 10px;
  margin: 20px 0px 0px 0px;
}

.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.numbertext {
  color: black;
  font-size: 12px;
  margin: 10px 10px 10px 15px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.actives,
.dot:hover {
  background-color: #717171;
}

/* Responsive columns */
@media screen and (max-width: 1100px) {
  .column {
    width: 100%;
    display: block;
    float: left;
    margin-bottom: 20px;
  }

  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
