.sidemenu-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #e6e6e6;
  width: 180px;
  padding-top: 10px;
  .back-dashboard {
    font-style: italic;
  }
  .list-menu {
    padding: 10px 20px;
    a {
      color: #10187d !important;
      text-decoration: none;
    }
  }
}

$breakpoint-non-hd: 1366px;
@media (max-width: $breakpoint-non-hd) {
  .sidemenu-wrapper {
    font-size: 12px;
  }
}
