 
  .signature-pad {
    width:100%;
    height:100%;
    background-color: white;
  }

  //skrining form
  .float {
    width: 120px;
    height: 40px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: left;
    box-shadow: 1px 1px 2px #999;
    z-index: 100;
    display: inline-flex;
  }
  .myfloat-text {
    font-size: 14pt;
    margin: .4em 0 0 .6em;
  }
  
  @media (min-width:1025px) {
      .flex-container {
          align-items: center;
          display: flex;
          justify-content: center;
      }
      .wrapper-vaccine{
          width:780px
      }
  
      .nik {
          margin-left: 80px
      }
      .submit {
          height:50px;
          width:740px
      }
      .answer{
          padding-left: 30px;
          margin-top: 20px;
          margin-bottom: 20px;
      }

      //   skrining form
      .caution{
          margin-top: 250px;
          color:red;
          text-align: center
      }
  }
  
  @media only screen and (max-width: 600px) {
      .nik {
          margin-left: 0px
      }
    //   .wrapper-vaccine{
    //       width:570px
    //   }
  
      .submit {
          height:50px;
          width:100%;
      }

    // skrining form
    .caution{
        margin-top: 150px;
        color:red;
        text-align: center
    }

    .font-caution{
        font-size: 1.25rem;
    }
  }
  
  @media print
      {
          .non-printable  { visibility:hidden; }
      }
  