.content-form-res {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.card-form-res {
  transition: 0.3s;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #a6bbd6;
  padding: 20px;
}

.card-col-res {
  flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
