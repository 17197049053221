.clickableTd {
  color: blue !important;
  cursor: pointer;
}

#mr-button:hover {
  color: white;
}

#mr-button:hover .fa-check {
  color: white;
}