.sign-container-res {
  position: relative;
}

.sign-page-res .form-content-res {
  display: flex;
}

.sign-page-res .form-content-res h3 {
  font-size: 42px;
  line-height: 52px;
  color: #fff;
  margin-top: 15px;
}

.sign-page-res .form-content-res h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
}

.sign-page-res .form-content-res p {
  margin-top: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #e2e2e2;
}

.sign-page-res .form-content-res a.btn {
  text-align: center;
  margin-bottom: 70px;
  margin-top: 40px;
}

.sign-page-res .form-left-res {
  width: 100%;
  float: left;
  padding: 10px;
  box-sizing: border-box;
}

.sign-page-res .form-right,
.sign-page-res .form-right.donation-right {
  width: 70%;
  float: left;
  text-align: center;
  background-size: cover;
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
}

.sign-page-res .overlay {
  min-height: inherit;
  min-height: 100%;
}

.sign-page-res .grid-info-form {
  top: 50%;
  left: 50%;
  text-align: center;
  color: #fff;
  transform: translate(-50%, -50%);
  max-width: 500px;
  margin: 0 auto;
  position: absolute;
}

.sign-page-res .signin-form-res {
  margin-top: 25px;
  width: 100%;
  overflow: hidden;
}

.sign-page-res .middle-res {
  margin-top: 20px;
}

.sign-page-res .middle-res h4 {
  font-size: 20px;
  color: #090e0d;
  font-weight: 600;
  text-transform: capitalize;
}

.sign-page-res .middle-res span {
  font-family: Montserrat;
  font-size: 18px;
  color: #090e0d;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 20px;
  height: 20px;
  width: 100%;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.sign-page-res .middle-res p {
  max-width: 100%;
  margin: auto;
  margin-top: 5px;
  color: #666;
  font-size: 14px;
}

.sign-page-res .signin-form-res label {
  color: #090e0d;
  font-size: 17px;
  display: block;
  line-height: 20px;
}

.sign-page-res .signin-form-res label a {
  color: #090e0d;
}

.sign-page-res .signin-form-res label a:hover {
  color: #38a8ff;
}

.sign-page-res .signin-form-res .form-input-res {
  margin-bottom: 15px;
}
.sign-page-res .signin-form-res .form-input-1-res {
  margin-bottom: 15px;
}

.sign-page-res .form-input-res textarea {
  background: #fff;
  outline: none;
  width: 100%;
  height: 100px;
  font-size: 15px;
  padding: 10px 10px;
  color: #090e0d;
  border: 2px solid #beced0;
}

.sign-page-res .form-input-res textarea:focus {
  border: 2px solid #38a8ff;
  background: #fff;
}

.sign-page-res .signin-form-res button.btn {
  background: #38a8ff;
  color: #fff;
  text-decoration: none;
  padding: 0px 30px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 45px;
  margin-top: 30px;
  border: none;
}

.sign-page-res .signin-form-res p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #53575b;
}

.sign-page-res .signin-form-res span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 5px;
  color: #a1a4a8;
}

.sign-page-res .signin-form-res button.btn:hover {
  background: transparent;
  border: 2px solid #38a8ff;
  color: #38a8ff;
}

.sign-page-res .form-content-res p.agree {
  font-size: 15px;
  line-height: 20px;
  color: #666;
}

.sign-page-res .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sign-page-res .checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #beced0;
}

.sign-page-res .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sign-page-res .container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sign-page-res .donate-form .infor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20;
  margin-bottom: 30px;
}

.sign-page-res .copy-right p {
  font-size: 15px;
  line-height: 29px;
  color: #666;
}

.sign-page-res .copy-right p a {
  color: #666;
}

.sign-page-res .copy-right p a:hover {
  color: #52a494;
}

.register-logo-res img {
  height: 120px;
  width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*//copy-right*/
@media (max-width: 1080px) {
  .sign-page-res .form-left-res {
    width: 40%;
  }

  .sign-page-res .form-right {
    width: 60%;
  }

  .sign-page-res .middle-res {
    margin-top: 20px;
  }

  .sign-page-res .middle-res h4 {
    font-size: 24px;
    line-height: 24px;
  }

  .sign-page-res .form-content-res h3 {
    font-size: 45px;
  }

  .sign-page-res .signin-form-res {
    margin-top: 45px;
  }

  .sign-page-res .grid-info-form {
    width: 100%;
  }

  .sign-page-res .donate-form .form-one {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

@media (max-width: 900px) {
  .sign-page-res .form-left-res {
    padding: 10px;
  }
}

@media (max-width: 800px) {
  .sign-page-res .grid-info-form {
    padding: 0 15px;
  }

  .sign-page-res .form-content-res h3 {
    font-size: 38px;
    margin-top: 20px;
  }

  .sign-page-res .signin-form-res button.btn {
    margin-top: 20px;
  }

  .sign-page-res .middle-res p {
    margin-top: 10px;
  }
}

@media (max-width: 736px) {
  .sign-page-res .signin-form-res label {
    font-size: 16px;
  }

  .sign-page-res .signin-form-res {
    margin-top: 30px;
  }

  .sign-page-res .middle-res p {
    font-size: 16px;
  }

  .sign-page-res a.sign-in {
    font-size: 20px;
  }

  .sign-page-res .middle-res h4 {
    font-size: 20px;
    line-height: 20px;
  }

  .sign-page-res .form-content-res a.btn {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

@media (max-width: 667px) {
  .sign-page-res .form-content-res {
    flex-direction: column;
  }

  .sign-page-res .form-left-res {
    padding: 10px 10px;
    width: 100%;
  }

  .sign-page-res .form-right {
    width: 100%;
  }

  .sign-page-res .form-right.donation-right {
    width: 100%;
  }

  .sign-page-res .grid-info-form {
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    position: static;
    padding: 55px 15px;
  }

  .form-content-res p {
    margin-top: 25px;
    font-size: 15px;
  }
}
