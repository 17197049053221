/*
Author: W3layouts
Author URL: http://w3layouts.com
 */
html {
  scroll-behavior: smooth;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
  display: -webkit-flex;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

button,
.btn,
select {
  cursor: pointer;
}

a {
  text-decoration: none;
}

iframe {
  border: none;
}

ul {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

p {
  color: #666;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-sticky {
  position: sticky;
}

.btn,
button,
.actionbg,
input {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
}

.btn:hover,
button:hover {
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  -ms-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
}

.title-text {
  text-shadow: 0 10px 20px rgba(0, 0, 0, 0.48), 0 4px 8px rgba(0, 0, 0, 0.24);
}

/*--/warp-login--*/
.warp-login {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .warp-login {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .warp-login {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .warp-login {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .warp-login {
    max-width: 1140px;
  }
}

.warp-login-full {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/*--//warp-login--*/
.sign-container {
  position: relative;
}

.sign-page .form-content {
  display: flex;
}

.sign-page .form-content h3 {
  font-size: 42px;
  line-height: 52px;
  color: #fff;
  margin-top: 15px;
}

.sign-page .form-content h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
}

.sign-page .form-content p {
  margin-top: 25px;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #e2e2e2;
}

.sign-page .form-content a.btn {
  text-align: center;
  margin-bottom: 70px;
  margin-top: 40px;
}

.read-more-1 {
  padding: 8px 40px;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  background: #38a8ff;
  border: 2px solid transparent;
  margin-top: 20px;
  line-height: 26px;
  text-transform: uppercase;
}

.read-more-1:hover {
  background: transparent;
  border: 2px solid #38a8ff;
}

.sign-page .form-left {
  width: 30%;
  float: left;
  padding: 30px;
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: 60px;
}

.sign-page .form-right,
.sign-page .form-right.donation-right {
  width: 70%;
  float: left;
  text-align: center;
  //background: url(../images/banner1.jpg) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
}

.sign-page .overlay {
  // background: rgba(34, 27, 20, 0.4392156862745098);
  min-height: inherit;
  min-height: 100%;
}

.sign-page .grid-info-form {
  top: 50%;
  left: 50%;
  text-align: center;
  color: #fff;
  transform: translate(-50%, -50%);
  max-width: 500px;
  margin: 0 auto;
  position: absolute;
}

.sign-page .signin-form {
  margin-top: 25px;
  width: 100%;
  overflow: hidden;
}

.sign-page .middle {
  margin-top: 80px;
}

.sign-page .middle h4 {
  font-size: 34px;
  color: #090e0d;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 40px;
}

.sign-page .middle p {
  max-width: 100%;
  margin: auto;
  margin-top: 5px;
  color: #666;
  font-size: 16px;
}

.sign-page .signin-form label {
  color: #090e0d;
  font-size: 15px;
  display: block;
  line-height: 33px;
}

.sign-page .signin-form label a {
  color: #090e0d;
}

.sign-page .signin-form label a:hover {
  color: #52a494;
}

.sign-page .signin-form .form-input {
  margin-bottom: 15px;
}

.sign-page .form-input input {
  background: #fff;
  outline: none;
  width: 100%;
  font-size: 17px;
  padding: 10px 15px;
  color: #090e0d;
  border: 2px solid #beced0;
}

.sign-page .form-input input:focus {
  border: 2px solid #52a494;
  background: #fff;
}

.sign-page .signin-form button.btn {
  background: #38a8ff;
  color: #fff;
  text-decoration: none;
  padding: 0px 30px;
  text-align: center;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 45px;
  margin-top: 30px;
  border: none;
}

.sign-page .signin-form button.btn:hover {
  background: transparent;
  border: 2px solid #38a8ff;
  color: #38a8ff;
}

.sign-page .form-content p.agree {
  font-size: 15px;
  line-height: 20px;
  color: #666;
}

.sign-page .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sign-page .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sign-page .checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #beced0;
}

.sign-page .container:hover input ~ .checkmark {
  background-color: #beced0;
}

.sign-page .container input:checked ~ .checkmark {
  background-color: #38a8ff;
}

.sign-page .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.sign-page .container input:checked ~ .checkmark:after {
  display: block;
}

.sign-page .container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.sign-page .donate-form .infor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20;
  margin-bottom: 30px;
}

/*/copy-right*/
.sign-page .copy-right p {
  font-size: 15px;
  line-height: 29px;
  color: #666;
}

.sign-page .copy-right p a {
  color: #666;
}

.sign-page .copy-right p a:hover {
  color: #52a494;
}

/*//copy-right*/
@media (max-width: 1080px) {
  .sign-page .form-left {
    width: 40%;
  }

  .sign-page .form-right {
    width: 60%;
  }

  .sign-page .middle {
    margin-top: 80px;
  }

  .sign-page .middle h4 {
    font-size: 30px;
    line-height: 30px;
  }

  .sign-page .form-content h3 {
    font-size: 45px;
  }

  .sign-page .signin-form {
    margin-top: 45px;
  }

  .sign-page .grid-info-form {
    width: 100%;
  }

  .sign-page .donate-form .form-one {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}

@media (max-width: 900px) {
  .sign-page .form-left {
    padding: 20px;
  }
}

@media (max-width: 800px) {
  .sign-page .grid-info-form {
    padding: 0 15px;
  }

  .sign-page .form-content h3 {
    font-size: 38px;
    margin-top: 20px;
  }

  .sign-page .signin-form button.btn {
    margin-top: 20px;
  }

  .sign-page .middle p {
    margin-top: 10px;
  }
}

@media (max-width: 736px) {
  .sign-page .signin-form label {
    font-size: 14px;
  }

  .sign-page .signin-form {
    margin-top: 30px;
  }

  .sign-page .middle p {
    font-size: 16px;
  }

  .sign-page a.sign-in {
    font-size: 20px;
  }

  .sign-page .middle h4 {
    font-size: 26px;
    line-height: 30px;
  }

  .sign-page .form-content a.btn {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

@media (max-width: 667px) {
  .sign-page .form-content {
    flex-direction: column;
  }

  .sign-page .form-left {
    padding: 30px 30px;
    width: 100%;
  }

  .sign-page .form-right {
    width: 100%;
  }

  .sign-page .form-right.donation-right {
    width: 100%;
  }

  .sign-page .signin-form {
    margin-bottom: 0px;
  }

  .sign-page .form-left {
    min-height: 400px;
    padding-bottom: 50px;
  }

  .sign-page .grid-info-form {
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    position: static;
    padding: 55px 15px;
  }

  .sign-page .form-content a.btn {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .copy-right {
    padding-bottom: 20px;
  }

  .form-content p {
    margin-top: 25px;
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .sign-page .middle {
    margin-top: 60px;
  }
}

@media (max-width: 480px) {
  .sign-page .form-left {
    padding: 30px 16px;
    padding-bottom: 50px;
  }
}

@media (max-width: 415px) {
  .sign-page .form-content h3 {
    font-size: 35px;
    line-height: 38px;
  }

  .sign-page .signin-form label {
    font-size: 13px;
  }

  .sign-page .grid-info-form {
    padding: 30px 15px;
  }
}

@media (max-width: 390px) {
  .sign-page .form-content h3 {
    font-size: 32px;
    line-height: 36px;
  }

  .sign-page .signin-form label {
    font-size: 13px;
  }
}
