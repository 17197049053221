.nielsen-layout {
  display: flex;
  height: 100%;
  font-size: 14px;
}

$breakpoint-mac: 1680px;
@media (max-width: $breakpoint-mac) {
  .nielsen-layout {
    font-size: 12px;
  }
}

$breakpoint-non-hd: 1366px;
@media (max-width: $breakpoint-non-hd) {
  .nielsen-layout {
    font-size: 10px;
  }
}
